<template>
  <section class='d-flex flex-column justify-center align-center mt-1'>
    <article class='d-flex flex-column align-center mt-4'>
      <h1 class='text-center text-gold'>{{ $t('Message for the moment') }}</h1>
      <span class='mt-5 text-center font-italic'>{{ $t('We recommend to use this at max three times to take advantage of Universe synchronism effect. If you use more than that, it will be almost the same like reading normally the messages at home page')}}</span>
      <Button class='my-auto' @click='viewMomentPost' v-if='viewPostEnabled' :width='250' :height='60'>{{ $t('View') }}</Button>
      <div class='post-ctn'>
        <v-fade-transition group>
          <div v-if='selecting' class='d-flex flex-column align-center my-15' key='progress'>
            <span class='my-5'>Selecionando...</span>
            <v-progress-circular  class='mt-5' :size='150' :width='10' color='primary' indeterminate></v-progress-circular>
          </div>
          <Post v-if='selected' class='my-15' :post='post' @postLikeToggle="postLikeToggled" key='post'/>
        </v-fade-transition>
      </div>
      <span class="back text-center mt-5" @click="$router.back()">{{ $t('Back') }}</span>
    </article>
  </section>
</template>
<script>
export default {
  name: 'MomentPosts',
  components: {
    Button: () => import('@/components/ui/Button'),
    Post: () => import('@/components/Post'),
  },
  data: () => ({
    user: null,
    post: {},
    posts: [],
    viewPostEnabled: true,
    selecting: false,
    selected: false,
    selectInterval: {},
    selectProgress: 0,
    storeWatchers: {
      posts: null,
      user: null,
    } 
  }),
  beforeDestroy () {
    this.storeWatchers.posts()
    this.storeWatchers.user()
  },
  methods: {
    watchAndSetPosts () {
      this.$store.dispatch('post/watchPosts') 
      this.storeWatchers.posts = this.$store.watch(
        state => state.post.posts,
        posts => { this.posts = posts },
        { deep: true } 
      )
    },
    watchAndSetUser () {
      this.storeWatchers.user = this.$store.watch(
        state => state.user.user,
        changedUser => { 
          this.user = changedUser
          const likedPosts = changedUser?.likedPosts || []
          this.setLikeOnPosts(likedPosts)
        },
        { deep: true } 
      )
    },
    setLikeOnPosts (likedPosts) {
      if (likedPosts.length === 0) {
        this.posts = this.posts.map(post => { 
          post.liked = false 
          return post
        })
        return
      }

      likedPosts.forEach(postID => {
        const foundIndex = this.posts.findIndex(findingPost => findingPost.id === postID)

        if (foundIndex > -1) 
          this.posts[foundIndex].liked = true
      })
    },
    postLikeToggled (post) {
      this.updateUserLikedPosts(post)   
    },
    async updateUserLikedPosts (post) {
      let likedPosts = this.$store.getters['user/user']?.likedPosts || []

      if (post.liked) 
        likedPosts.push(post.id)
      else 
        likedPosts = likedPosts.filter(filteringPost => filteringPost !== post.id)

      await this.$store.dispatch('user/updateUser', { likedPosts })
    },
    viewMomentPost () {
      this.selecting = true
      this.startTimeout()
      this.viewPostEnabled = false
    },
    startTimeout () {
      this.$store.dispatch('post/getPosts').then(() => {
        this.posts = this.$store.getters['post/posts']
        this.post = this.posts[~~(Math.random() * this.posts.length)]
        this.watchAndSetPosts() 
      })

      this.user = this.$store.getters['user/user'] // There, this.user assignment isnt inside an dispatch promise result due firebase onAuthStateChanged already doing that dispatch
      this.watchAndSetUser()

      setTimeout(() => {
        this.selecting = false
        setTimeout(() => { this.selected = true }, 350)
      }, 4000)
    }
  }
}
</script>
<style lang='scss' scoped>
  @import '@/assets/scss/_classes.scss';

  article {
    max-width: 900px;
    min-height: calc(100vh - 200px); 

    h1 { 
      font-size: 30px;
      font-family: 'Pacifico', cursive; 
      text-shadow: 1px 1px 1px gray;
    }

    .post-ctn { 
      width: 100%;
      height: auto;
    }
  }

  span { 
    font-size: 18px;

    &.back {
      font-size: 15px;

      &:hover { 
        cursor: pointer;
        text-decoration: underline; 
      }
    }
  }

  .fade-enter-active, .fade-leave-active { transition: all .5s ease-in-out; }

  .fade-enter, .fade-leave-to { opacity: 0; }
</style>